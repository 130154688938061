const MEMBER_INFO_KEY = 'member_info_key';

function setMemberInfoLocal(memberInfo) {
  return localStorage.setItem(MEMBER_INFO_KEY, JSON.stringify(memberInfo));
}

function getMemberInfoLocal() {
  const item = localStorage.getItem(MEMBER_INFO_KEY);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
}

function removeMemberInfoLocal() {
  return localStorage.removeItem(MEMBER_INFO_KEY);
}

export { setMemberInfoLocal, getMemberInfoLocal, removeMemberInfoLocal };
