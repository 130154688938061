// 封装基于ls存取删三个方法

const TOKENKEY = 'token_key';

function setTokenLocal(token) {
  return localStorage.setItem(TOKENKEY, token);
}

function getTokenLocal() {
  return localStorage.getItem(TOKENKEY);
}

function removeTokenLocal() {
  return localStorage.removeItem(TOKENKEY);
}

export { setTokenLocal, getTokenLocal, removeTokenLocal };
