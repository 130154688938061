import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { request } from '@/utils';
import log from '@/utils/logger';
import router from '@/router';
import { clearAllUserInfo } from '@/store/modules/user';
import { getTokenLocal } from '@/utils/token';
import { clearVoices } from '@/store/modules/voices';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

function useAxiosInterceptor() {
  const dispatch = useDispatch();

  const navigateToLogin = debounce(() => {
    router.navigate('/login');
  }, 1000);

  useEffect(() => {
    // 添加请求拦截器
    // 在请求发送之前 做拦截 插入一些自定义的配置 [参数的处理]
    const requestInterceptor = request.interceptors.request.use(
      config => {
        // 操作这个config 注入token数据
        // 1. 获取到token
        // 2. 按照后端的格式要求做token拼接
        const token = getTokenLocal();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    // 添加响应拦截器
    // 在响应返回到客户端之前 做拦截 重点处理返回的数据
    const responseInterceptor = request.interceptors.response.use(
      response => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        return response.data;
      },
      error => {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        // 监控401 token失效
        log.error(error);
        // 检查是否有响应以及响应状态码
        if (error.response) {
          const status = error.response.status;
          switch (status) {
            case 401:
              // 未授权
              dispatch(clearAllUserInfo());
              dispatch(clearVoices());
              navigateToLogin();
              break;
            case 403:
              // 禁止访问
              log.error('没有权限访问该资源');
              break;
            case 404:
              // 未找到
              log.error('请求的资源不存在');
              break;
            case 500:
              // 服务器错误
              log.error('服务器内部错误');
              break;
            default:
              // 其他错误
              log.error(`发生错误：${status}`);
          }
        } else if (error.request) {
          // 请求已发送，但没有收到响应
          toast.warn('服务器未响应，请稍后重试');
        } else {
          // 请求设置引发的错误
          log.error('请求发送失败：' + error.message);
        }

        return Promise.reject(error);
      }
    );

    // 清理函数，在组件卸载时移除拦截器
    return () => {
      request.interceptors.request.eject(requestInterceptor);
      request.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]); // 依赖项数组中包含dispatch，确保拦截器随dispatch的变化而正确设置
}

export default useAxiosInterceptor;
