// 和用户相关的状态管理
import { createSlice } from '@reduxjs/toolkit';
import { getVoiceAPI, getCustomVoicesAPI, getBuiltlinVoicesAPI } from '@/apis/voices';
import log from '@/utils/logger';

const voicesStore = createSlice({
  name: 'user',
  // 数据状态
  initialState: {
    builtinVoices: [],
    customVoices: [],
  },
  // 同步修改方法
  reducers: {
    setBuiltinVoices(state, action) {
      state.builtinVoices = action.payload;
    },
    setCustomVoices(state, action) {
      state.customVoices = action.payload;
    },
    updateVoice(state, action) {
      const newVoice = action.payload;
      const index = state.customVoices.findIndex(voice => voice.voice_id === newVoice.voice_id);
      if (index !== -1) {
        state.customVoices[index] = newVoice;
      }
    },
    clearVoices(state) {
      state.builtinVoices = [];
      state.customVoices = [];
    },
  },
});

// 解构出actionCreater
const { setBuiltinVoices, setCustomVoices, updateVoice, clearVoices } = voicesStore.actions;

// 获取reducer函数
const voicesReducer = voicesStore.reducer;

// 获取内置声音列表异步方法
const fetchBuiltinVoices = () => {
  return async dispatch => {
    try {
      const res = await getBuiltlinVoicesAPI();
      dispatch(setBuiltinVoices(res.data.voices));
    } catch (error) {
      log.error(error);
      throw error;
    }
  };
};

// 获取用户声音列表异步方法
const fetchCustomVoices = () => {
  return async dispatch => {
    try {
      const res = await getCustomVoicesAPI();
      dispatch(setCustomVoices(res.data.voices));
      return res;
    } catch (error) {
      log.error(error);
      throw error;
    }
  };
};

// 获取单个声音异步方法
const fetchVoice = voice_id => {
  return async dispatch => {
    try {
      const res = await getVoiceAPI(voice_id);
      dispatch(updateVoice(res.data));
      return res;
    } catch (error) {
      log.error(error);
      throw error;
    }
  };
};

export { fetchBuiltinVoices, fetchCustomVoices, fetchVoice, clearVoices };

export default voicesReducer;
