const USER_INFO_KEY = 'user_info_key';

function setUserInfoLocal(userInfo) {
  return localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
}

function getUserInfoLocal() {
  const item = localStorage.getItem(USER_INFO_KEY);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch (error) {
    return null;
  }
}

function removeUserInfoLocal() {
  return localStorage.removeItem(USER_INFO_KEY);
}

export { setUserInfoLocal, getUserInfoLocal, removeUserInfoLocal };
