// src/logger.js
import log from 'loglevel';

// 设置默认日志级别
// 在开发环境中使用DEBUG，在生产环境中使用WARN
if (process.env.NODE_ENV === 'development') {
  log.setLevel(log.levels.DEBUG);
} else {
  log.setLevel(log.levels.WARN);
}

export default log;
