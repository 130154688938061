import { toast } from 'react-toastify';

function validatePhone(phone) {
  const phoneRegex = /^[1-9]\d{10}$/;

  if (!phone.trim()) {
    toast.warn('手机不能为空');
    return false;
  } else if (!phoneRegex.test(phone)) {
    toast.warn('无效的手机号码');
    return false;
  }

  return true; // 验证通过
}

function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  if (!email.trim()) {
    toast.warn('邮箱不能为空');
    return false;
  } else if (!emailRegex.test(email)) {
    toast.warn('无效的邮箱地址');
    return false;
  }

  return true; // 验证通过
}

function validateNotNull(target, name) {
  if (!target.trim()) {
    toast.warn(`${name}不能为空`);
    return false;
  }
  return true;
}

const isValidFileType = (file, allowedTypes) => {
  return allowedTypes.includes(file.type);
};

const isValidFileSize = (file, maxSizeInBytes) => {
  return file.size <= maxSizeInBytes;
};

const isValidAudioDuration = (file, minDuration, maxDuration) => {
  return new Promise(resolve => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    // 成功加载元数据
    audio.addEventListener('loadedmetadata', () => {
      const duration = audio.duration;
      if (duration >= minDuration && duration <= maxDuration) {
        resolve({ isValid: true });
      } else {
        resolve({
          isValid: false,
          reason: `文件 ${file.name} 时长不在${minDuration}秒到${maxDuration}秒之间`,
        });
      }
    });
    // 处理加载错误
    audio.addEventListener('error', e => {
      resolve({ isValid: false, reason: `不支持文件"${file.name}"`, error: e });
    });
  });
};

export {
  validatePhone,
  validateEmail,
  validateNotNull,
  isValidFileType,
  isValidFileSize,
  isValidAudioDuration,
};
