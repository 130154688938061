// AppWrapper.js
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import useAxiosInterceptor from './hooks/useAxiosInterceptor';

const AppWrapper = () => {
  useAxiosInterceptor();

  return <RouterProvider router={router} />;
};

export default AppWrapper;
