// 用户相关的所有请求
import { request } from '@/utils';

// 获取验证码图片
export function getCaptchaImageAPI() {
  return request({
    url: '/captcha',
    method: 'GET',
  });
}

// 发送手机或邮箱验证码
export function sendVerifyCodeAPI(data) {
  return request({
    url: '/verifycode',
    method: 'POST',
    data: data,
  });
}

// 修改手机号
export function updatePhoneAPI(data) {
  return request({
    url: '/update/phone',
    method: 'POST',
    data: data,
  });
}

// 修改邮箱
export function updateEmailAPI(data) {
  return request({
    url: '/update/email',
    method: 'POST',
    data: data,
  });
}

// 修改密码
export function updatePasswordAPI(data) {
  return request({
    url: '/update/password',
    method: 'POST',
    data: data,
  });
}

// 修改用户名
export function updateUsernameAPI(data) {
  return request({
    url: '/user',
    method: 'POST',
    data: data,
  });
}

// 修改头像
export function updateAvatarAPI(data) {
  return request({
    url: '/update/avatar',
    method: 'POST',
    data: data,
  });
}

// 手机或邮箱验证码登录
export function loginVerifyCodeAPI(data) {
  return request({
    url: '/login',
    method: 'POST',
    data: data,
  });
}

// 密码登录请求
export function loginPasswordAPI(formData) {
  return request({
    url: '/login',
    method: 'POST',
    data: formData,
  });
}

// 获取用户信息
export function getProfileAPI() {
  return request({
    url: '/user',
    method: 'GET',
  });
}

// 注销用户
export function deregisterAPI() {
  return request({
    url: '/user',
    method: 'DELETE',
  });
}

// 获取用户会员信息
export function getMemberInfoAPI() {
  return request({
    url: '/user/member',
    method: 'GET',
  });
}

// 获取会员等级列表
export function getMemberLevelsAPI() {
  return request({
    url: '/member',
    method: 'GET',
  });
}

// 获取订单价格
export function obtainOrderPriceAPI(data) {
  return request({
    url: '/member/price',
    method: 'POST',
    data: data,
  });
}

// 创建订单
export function createOrderAPI(data) {
  return request({
    url: '/member/order',
    method: 'POST',
    data: data,
  });
}

// 查询订单状态
export function inquiryOrderStatusAPI(data) {
  return request({
    url: '/order/status',
    method: 'POST',
    data: data,
  });
}

export function feedbackAPI(data) {
  return request({
    url: '/contacts',
    method: 'POST',
    data: data,
  });
}
