// axios的封装处理
import axios from 'axios';

axios.defaults.withCredentials = true;

// 1. 根域名配置
// 2. 超时时间
// 3. 请求拦截器 / 响应拦截器
const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
});

export { request };
