// 和用户相关的状态管理

import { createSlice } from '@reduxjs/toolkit';
import {
  setTokenLocal,
  getTokenLocal,
  removeTokenLocal,
  getUserInfoLocal,
  setUserInfoLocal,
  removeUserInfoLocal,
  setMemberInfoLocal,
  getMemberInfoLocal,
  removeMemberInfoLocal,
} from '@/utils';
import { loginPasswordAPI, loginVerifyCodeAPI, getProfileAPI, getMemberInfoAPI } from '@/apis/user';
import log from '@/utils/logger';

const userStore = createSlice({
  name: 'user',
  // 数据状态
  initialState: {
    token: getTokenLocal() || '',
    userInfo: getUserInfoLocal() || {},
    memberInfo: getMemberInfoLocal() || {},
  },
  // 同步修改方法
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      setTokenLocal(action.payload);
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
      setUserInfoLocal(action.payload);
    },
    setMemberInfo(state, action) {
      state.memberInfo = action.payload;
      setMemberInfoLocal(action.payload);
    },
    clearAllUserInfo(state) {
      state.token = '';
      state.userInfo = {};
      state.memberInfo = {};
      removeTokenLocal();
      removeUserInfoLocal();
      removeMemberInfoLocal();
    },
  },
});

// 解构出actionCreater

const { setToken, setUserInfo, clearAllUserInfo, setMemberInfo } = userStore.actions;

// 获取reducer函数

const userReducer = userStore.reducer;

// 密码登录获取token异步方法封装
const fetchPasswordLogin = loginForm => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      loginPasswordAPI(loginForm)
        .then(res => {
          dispatch(setToken(res.data.token));
          resolve(res);
        })
        .catch(error => {
          log.error('密码登录失败,error:', error);
          reject(error);
        });
    });
  };
};

// 验证码获取token
const fetchVerifyCodeLogin = loginForm => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      loginVerifyCodeAPI(loginForm)
        .then(res => {
          dispatch(setToken(res.data.token));
          resolve(res);
        })
        .catch(error => {
          log.error('验证码登录失败,error:', error);
          reject(error);
        });
    });
  };
};

// 获取个人用户信息异步方法
const fetchUserInfo = () => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      getProfileAPI()
        .then(res => {
          dispatch(setUserInfo(res.data));
          resolve(res);
        })
        .catch(error => {
          log.error('获取用户信息失败,error:', error);
          reject(error);
        });
    });
  };
};

// 获取用户会员信息异步方法
const fetchMemberInfo = () => {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      getMemberInfoAPI()
        .then(res => {
          dispatch(setMemberInfo(res.data));
          resolve(res);
        })
        .catch(error => {
          log.error('获取会员信息失败,error:', error);
          reject(error);
        });
    });
  };
};

export {
  fetchPasswordLogin,
  fetchVerifyCodeLogin,
  fetchUserInfo,
  fetchMemberInfo,
  clearAllUserInfo,
};

export default userReducer;
