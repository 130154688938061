import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Loading from '@/components/Loading';
import log from '@/utils/logger';
import ErrorBoundary from '@/components/ErrorBoundary';

const App = lazy(() => import('@/App'));
const SpeechSynthesisPage = lazy(() => import('@/pages/SpeechSynthesisPage'));
const VoiceClonePage = lazy(() => import('@/pages/VoiceClonePage'));
const VoiceLibraryPage = lazy(() => import('@/pages/VoiceLibraryPage'));
const NotFoundPage = lazy(() => import('@/pages/NotFoundPage'));
const HomePage = lazy(() => import('@/pages/HomePage'));
const LoginPage = lazy(() => import('@/pages/LoginPage'));
const FaqPage = lazy(() => import('@/pages/FaqPage'));
const PricePage = lazy(() => import('@/pages/PricePage'));
const ContactUs = lazy(() => import('@/pages/ContactUs'));

// 检查和处理加载块失败的错误
const handleLoadingChunkFailed = error => {
  // 检查是否为特定的加载块失败错误
  if (/Loading chunk [\d]+ failed/.test(error.message)) {
    log.error('Loading chunk failed:', error.message);
    // 返回一个解析为错误组件的 Promise，兼容 React.lazy
    return import('@/components/ErrorComponent');
  }
  // 对于其他类型的错误，重新抛出，使其能被上层错误边界捕获
  throw error;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<></>}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Suspense>
    ),
    children: [
      // 设置为默认二级路由 一级路由访问的时候，它也能得到渲染
      {
        index: true,
        element: (
          <Suspense fallback={<></>}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: 'speechsynthesis',
        element: (
          <Suspense fallback={<Loading />}>
            <SpeechSynthesisPage />
          </Suspense>
        ),
      },
      {
        path: 'voiceclone',
        element: (
          <Suspense fallback={<Loading />}>
            <VoiceClonePage />
          </Suspense>
        ),
      },
      {
        path: 'voicelibrary',
        element: (
          <Suspense fallback={<Loading />}>
            <VoiceLibraryPage />
          </Suspense>
        ),
      },
      {
        path: 'price',
        element: (
          <Suspense fallback={<Loading />}>
            <PricePage />
          </Suspense>
        ),
      },
      {
        path: 'faq',
        element: (
          <Suspense fallback={<Loading />}>
            <FaqPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <LoginPage />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: '/contact',
    element: (
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <ContactUs />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <NotFoundPage />
        </ErrorBoundary>
      </Suspense>
    ),
  },
]);

export default router;
