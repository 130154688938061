import { request } from '@/utils';

// 获取单个声音
export function getVoiceAPI(voice_id) {
  return request({
    url: `/voices/${voice_id}`,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  });
}

// 获取用户声音列表
export function getCustomVoicesAPI() {
  return request({
    url: '/voices/user',
    method: 'GET',
  });
}

// 获取内置声音列表
export function getBuiltlinVoicesAPI() {
  return request({
    url: '/voices',
    method: 'GET',
  });
}

// 编辑声音信息
export function setVoiceInfoAPI(voice_id, formData) {
  return request({
    url: `/voices/${voice_id}/edit`,
    method: 'POST',
    data: formData,
  });
}

// 克隆声音
export function cloneVoiceAPI(formData, cancelToken) {
  return request({
    url: '/voices/add',
    method: 'POST',
    data: formData,
    timeout: 300000, // 5分钟超时.
    cancelToken: cancelToken,
  });
}

// 合成声音
export function textToSpeechAPI(voice_id, data) {
  return request({
    url: `/tts/${voice_id}`,
    method: 'POST',
    data: data,
  });
}

// 查询合成声音状态
export function getTextToSpeechStatusAPI(job_id) {
  return request({
    url: `/tts_status/${job_id}`,
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  });
}

// 收藏声音
export function collectVoiceAPI(voice_id) {
  return request({
    url: `/voices/${voice_id}/save`,
    method: 'POST',
  });
}

// 取消收藏声音
export function uncollectVoiceAPI(voice_id) {
  return request({
    url: `/voices/${voice_id}/del`,
    method: 'DELETE',
  });
}
