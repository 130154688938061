import { configureStore } from '@reduxjs/toolkit';
import userReducer from './modules/user';
import voicesReducer from './modules/voices';

export default configureStore({
  reducer: {
    user: userReducer,
    voices: voicesReducer,
  },
});
