import React, { Component } from 'react';
import log from '@/utils/logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // 更新状态，以便下一次渲染可以显示降级 UI
    log.error('ErrorBoundary caught an error:', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // 可以将错误日志到一个错误报告服务
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    log.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // 自定义错误界面
      return (
        <div className='flex min-h-screen flex-1 items-center justify-center bg-light'>
          <div className='w-full max-w-xl rounded-lg p-6 shadow-lg'>
            <h2 className='text-xl font-bold'>发生错误</h2>
            <p className='mt-2'>抱歉，我们的应用遇到了一些问题。</p>
            <div className='mt-4'>
              <button
                onClick={() => window.location.reload()}
                className='rounded border px-4 py-2 hover:bg-gray-80'
              >
                尝试重新加载
              </button>
            </div>
            <div className='mt-4 text-sm'>
              <details className='mt-2'>
                <summary className='mb-1 cursor-pointer'>点击查看错误详情</summary>
                {this.state.error && (
                  <p className='whitespace-pre-wrap'>{this.state.error.toString()}</p>
                )}
                {this.state.errorInfo && (
                  <p className='whitespace-pre-wrap'>{this.state.errorInfo.componentStack}</p>
                )}
              </details>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
