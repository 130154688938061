import React from 'react';

export default function Loading({ className, msg = 'Loading...' }) {
  return (
    <div className={`my-2 flex items-center justify-center ${className}`}>
      <span className='loading loading-spinner loading-md' />
      <span className='mx-2'>{msg}</span>
    </div>
  );
}
